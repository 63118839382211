import { Dialog } from 'vant';
import router from "../router";

class Socket {

  lock = false;
  errNum = 0;

  constructor({ url, open, message, close }) {
    if (url) {
      this.url = url;
    }
    if (open) {
      this.open = open;
    }
    if (message) {
      this.message = message;
    }
    if (close) {
      this.close = close;
    }

    // 消息储存数组
    this.recv_list = [];
    // 创建订阅者
    this.deps = new Dep();
    // 创建代理
    this.$recv_list = this.proxyList();
  }

  // 监听收到的命令
  proxyList() {
    let _this = this;
    return new Proxy(this.recv_list, {
      set(target, prop, value) {
        let obj = JSON.parse(value);
        // 通知订阅者干活
        // console.log(obj);
        _this.deps.notify(obj.cmd, value);
        return Reflect.set(...arguments);
      }
    })
  }

  onConnect(url = this.url) {
    if (!!window.WebSocket && window.WebSocket.prototype.send) {
      console.log("您的浏览器支持Websocket通信协议")
    } else {
      Toast.fail({
        message: 'Your browser does not support Websocket communication protocol, please use. Chrome or Edge browser!',
        overlay: true,
        duration: 2000
      });
      // alert("您的浏览器不支持Websocket通信协议，请使用Chrome或者Firefox浏览器！")
      return;
    }

    if (this.Socket) {
      this.Socket.close();
    }

    try {
      this.url = url;
      let Socket = new WebSocket(url);
      Socket.onopen = this.onOpen.bind(this);
      Socket.onmessage = this.onMessage.bind(this);
      Socket.onclose = this.onClose.bind(this);
      Socket.onerror = this.onError.bind(this);

      this.Socket = Socket;
    } catch (error) {
      console.error("连接失败： ", error);
    }
  }

  onOpen() {
    this.errNum = 0;
    if (this.open) {
      this.open();
    }
  }

  onSend(data) {
    if (!this.Socket || this.Socket.readyState !== 1) {
      console.warn("连接未打开");
      // alert("连接未打开");
      return;
    }
    console.log("向服务器发送消息：" + JSON.stringify(data));
    this.Socket.send(JSON.stringify(data));
  }

  onSendInfo(data, time = 3000) {
    if (!this.Socket || this.Socket.readyState !== 1) {
      console.warn("连接未打开");
      // alert("连接未打开");
      return new Promise((resolve, reject) => {
        reject("连接未打开")
      });
    }
    return new Promise((resolve, reject) => {
      try {
        // 添加订阅者
        let depStr = data.msg.slice(4);
        // console.log("depStr: " + depStr);
        this.deps.addDep(
          depStr,
          new Watch(
            function (data) {
              resolve(data);
            }
          )
        )
        this.onSend(data);
        setTimeout(() => {
          reject("超时");
        }, time)
      } catch (err) {
        reject(err)
      }
    })
  }

  // 接收到消息之后如果有订阅者就把消息给订阅者处理
  onMessage(event) {
    // 接收到的消息就push进去
    // console.log("event" + event.data);
    try {
      let data = JSON.parse(event.data);
      if (data.code && data.code === 500) {
        // 清除用户所有权限
        sessionStorage.removeItem("token");
        sessionStorage.removeItem("address");
        sessionStorage.removeItem("userinfo");
        sessionStorage.removeItem("info");
        sessionStorage.removeItem("time");
        sessionStorage.removeItem("page");
        sessionStorage.removeItem("deviceID");
        this.lock = true; // 标记为true后不会再进行重连操作
        this.closeWeb();
        let actID = sessionStorage.getItem("actID");
        Dialog.confirm({
          title: 'Error',
          message: data.msg,
          confirmButtonText: "Confirm",
          cancelButtonText: "Cancel",
        })
          .then(() => {
            router.push({ path: "/stack", query: { id: actID } });
          })
          .catch();
        // router.push("/login");
        return
      }
    } catch { }
    this.$recv_list.push(event.data);
    if (this.message) {
      this.message(event);
    }
  }

  // 0	CONNECTING 正在与服务端建立WebSocket连接，还没有连接成功
  // 1	OPEN 连接成功并打开，可以发送消息
  // 2	CLOSING	进行关闭连接的操作，且尚未关闭
  // 3	CLOSE	连接已关闭或不能打开
  getState() {
    try {
      return this.Socket.readyState;
    } catch (error) {
      return 3;
    }
  }

  onClose() {
    if (this.errNum >= 10) {
      Dialog.alert({
        title: 'Error',
        message: 'There seems to be a problem with your network and the server cannot be connected normally! Please check. the network or log in again',
      })
      return;
    }
    // 使用lock避免重复连接
    if (this.lock) return;
    if (this.close) {
      this.close(this.Socket);
    }
    this.lock = true;
    this.errNum++;
    setTimeout(() => {
      this.lock = false;
      this.onConnect();
      console.log('正在尝试重新连接,重连次数： ' + this.errNum);
    }, 1500)
  }

  closeWeb() {
    this.Socket.close();
    this.lock = true; // 不进行重连
  }

  onError(error) {
    console.warn("连接发生错误");
    console.warn(error);
  }

}

// 发布者
class Dep {
  constructor() {
    this.deps = {}
  }

  /* 
    添加订阅者
    @Param key 订阅名称
    @Param dep 订阅者
  */
  addDep(key, dep) {
    this.deps[key] = dep;
  }

  /* 
    通知订阅者起床干活
    @Param key 订阅名称
    @Param str 数据
  */
  notify(key, str) {
    if (this.deps.hasOwnProperty(key)) {
      this.deps[key].updata(str);
    }
  }
}

// 订阅者
class Watch {
  constructor(callBack) {
    this.callBack = callBack;
  }

  updata(str) {
    this.callBack(str);
  }
}

export { Socket }