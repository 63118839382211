<template>
  <div class="pzp-select">
    <div class="pzp-label" @click="changeState">
      <span>{{ label }}</span>
      <img v-if="state === 'up'" src="../assets/img/up.png" alt="" />
      <img v-if="state === 'down'" src="../assets/img/down.png" alt="" />
    </div>
    <div class="pzp-options" v-show="state === 'up'">
      <div class="pzp-options-title">
        <span class="left" @click="state = 'down'">cancel</span>
        <span class="center">select：</span>
        <span class="right"></span>
      </div>
      <div class="overflow">
        <div
          class="pzp-option-item"
          v-for="(item, index) in options"
          :key="index"
          @mouseover="hover(item)"
          @click="changeValue(item)"
          :class="hoveract === item.value ? 'act' : ''"
        >
          {{ item.label }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      state: "down",
      hoveract: "",
      label: "",
    };
  },
  props: {
    value: [Number, String],
    options: Array,
  },
  watch: {
    // 改变label
    value(newVal) {
      this.hoveract = newVal;
      this.options.forEach((item) => {
        if (item.value === newVal) {
          this.label = item.label;
        }
      });
    },
  },
  methods: {
    changeState() {
      if (this.state === "up") {
        this.state = "down";
      } else {
        this.state = "up";
      }
    },
    // 移过元素
    hover(item) {
      this.hoveract = item.value;
    },
    changeValue(item) {
      this.state = "down";
      this.label = item.label;
      this.$emit("input", item.value);
    },
    handleClickOutside(event) {
      // 判断点击的元素是否在自定义组件内部，不是的话收起来
      if (!this.$el.contains(event.target)) {
        this.state = "down";
      }
    },
  },
  created() {
    // this.label = this.options[0].label;
    if (this.value && this.options.length) {
      this.hoveract = this.options[0].value;
      this.hoveract = this.value;
      this.options.forEach((item) => {
        if (item.value === this.value) {
          this.label = item.label;
        }
      });
    }
  },
  mounted() {
    // 添加页面级别的 click 事件监听器
    document.addEventListener("click", this.handleClickOutside);
  },
  beforeDestroy() {
    // 移除页面级别的 click 事件监听器
    document.removeEventListener("click", this.handleClickOutside);
  },
};
</script>

<style lang="less" scoped>
.pzp-select {
  min-width: 50px;
  height: 36px;
  // margin: 0 4px;
  font-size: 14px;
  line-height: 32px;
  border: 1px solid #ddd;
  border-radius: 8px;
  position: relative;

  .pzp-label {
    height: 100%;
    padding: 0 8px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    span {
      display: inline-block;
      width: 100%;
      white-space: nowrap;
      overflow: hidden;
    }
    img {
      width: 16px;
      height: 14px;
    }
  }

  .pzp-options {
    width: 100%;
    height: 300px;
    padding-top: 20px;
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
    box-shadow: 0 0 6px 2px #c0c4cc;
    overflow: hidden;
    background-color: white;
    position: fixed;
    bottom: 0;
    left: 0;
    z-index: 999;

    .pzp-options-title {
      padding: 0 12px;
      font-size: 18px;
      height: 32px;
      line-height: 32px;
      color: #606266;
      display: flex;
      justify-content: space-between;
      .left,
      .right {
        display: inline-block;
        font-size: 16px;
        width: 50px;
      }
      .center {
        color: black;
      }
    }

    .overflow {
      height: 240px;
      overflow-y: auto;
      // display: flex;
      // flex-direction: column;
      // justify-content: center;
    }

    .pzp-option-item {
      white-space: nowrap;
      overflow: hidden;
      padding: 0 8px;
      text-align: center;
      color: #606266;
    }

    .pzp-option-item.act {
      background-color: #1e90ff;
      color: white;
    }
  }
}
</style>