import "./assets/css/style.less";
import Vue from "vue";
import router from "./router";
import store from "./store";

import Vant from 'vant';
import 'vant/lib/index.css';
Vue.use(Vant);

import { Locale } from 'vant';
// 引入英文语言包
import enUS from 'vant/es/locale/lang/en-US';

Locale.use('en-US', enUS);

// 全局设置toast默认参数 默认显示2s，打开遮罩
import { Toast } from "vant";
Toast.setDefaultOptions({ duration: 2000, overlay: true, className: "toast" });
Vue.use(Toast);

import pzpSelect from "./components/pzpSelect.vue";
Vue.component("pzp-select", pzpSelect)

import http from "./utils/request";
Vue.prototype.$http = http;

// websocket 挂载到全局 方便全局操作websocket
import { Socket } from "./utils/socket.js";
// new websocket类
let socket = new Socket({
  url: process.env.VUE_APP_WEB_URL,
  open: null,
  message: null,
  close: null,
});
Vue.prototype.$socket = socket;

new Vue({
  router,
  store,
  render: (h) => h("router-view"),
}).$mount("#app");
