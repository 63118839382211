import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    deviceID: "",
    name: "",
    options: [],
  },
  getters: {},
  mutations: {
    setDeviceID(state, deviceID) {
      state.deviceID = deviceID;
      sessionStorage.setItem("actID", deviceID)
      state.options.forEach((item) => {
        if (item.value === deviceID) {
          state.name = item.name;
        }
      })
    },
    setOptions(state, options) {
      let optionlist = JSON.parse(JSON.stringify(options));
      state.options = optionlist;
      if (optionlist.length > 0) {
        if (!state.deviceID) {
          state.deviceID = optionlist[0].value;
          sessionStorage.setItem("actID", optionlist[0].value)
          state.name = optionlist[0].name;
        }
      } else {
        state.deviceID = "";
        sessionStorage.removeItem("actID")
        state.name = "";
      }
    },
  },
  actions: {},
  modules: {},
});
