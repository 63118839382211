import Vue from "vue";
import VueRouter from "vue-router";

const Login = () => import("../views/LoginView.vue");
const Resetting = () => import("../views/ResetView.vue"); // 首页修改密码
const Stack = () => import("../views/StackView.vue");

const Index = () => import("../views/IndexView.vue");
const Home = () => import("../views/HomeView.vue");
const Data = () => import("../views/DataView.vue");
const Setting = () => import("../views/SettingView.vue");
const User = () => import("../views/UserView.vue");
const Record = () => import("../views/RecordView.vue");
const Device = () => import("../views/DeviceView.vue");
const Info = () => import("../views/InfoView.vue");
const Password = () => import("../views/PasswordView.vue");
const Instruct = () => import("../views/InstructView.vue");

Vue.use(VueRouter);

const router = new VueRouter({
  linkActiveClass: "active",
  routes: [
    { path: "/", redirect: "/login" },
    { path: "/login", component: Login, name: "login" },
    { path: "/resetting", component: Resetting, name: "resetting" },
    { path: "/stack", component: Stack, name: "stack" },
    {
      path: "/index", component: Index, name: "index", children: [
        { path: "", redirect: "home" },
        { path: "home", component: Home, name: "home" },
        { path: "data", component: Data, name: "data" },
        { path: "setting", component: Setting, name: "setting" },
      ]
    },
    { path: "/device", component: Device, name: "device" },
    { path: "/record", component: Record, name: "record" },
    { path: "/user", component: User, name: "user" },
    { path: "/info", component: Info, name: "info" },
    { path: "/password", component: Password, name: "password" },
    { path: "/instruct", component: Instruct, name: "instruct" },
  ],
});

import { Toast } from "vant";

router.beforeEach((to, from, next) => {
  const token = sessionStorage.getItem("token");
  let valid = true;
  if (token == null) {
    valid = false;
  }

  if (to.path !== "/login" && to.path !== "/resetting" && to.path !== "/stack" && !valid) {
    next("/login");
    Toast.fail({
      message: 'Login data is not detected, please login again!',
      overlay: true,
      duration: 2000
    });
  }
  next();
});

export default router;
